import client from "./http";

class ParticipantsService {
  constructor(axios) {
    this.axios = axios;
  }

  getParticipantsByClient(clientId, search) {
    return this.axios.get(`/clients/${clientId}/users/participants`,  {params: {search}});
  }

  importParticipants(programId, data) {
    return this.axios.post(`/programs/${programId}/users/participants/import`, data);
  }
}

const participantsService = new ParticipantsService(client);
export default participantsService;
